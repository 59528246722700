import { useMemo } from "react";
import { trackEvent } from "@leafly-com/web-utils";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import { getCurrentDispensary } from "redux/selectors/dispensary";
import { getUserPrefersMedical } from "redux/selectors/user";
import isDispensaryDualLicense from "utils/dispensary/isDispensaryDualLicense";

import AlertBanner from "components/botanic/AlertBanner";
import MedicalCrossIcon from "components/Icons/medical_cross.svg";

import links from "./links.json";

const MedicalInfo: React.FC = () => {
  const { route } = useRouter();
  const userPrefersMedical = useSelector(getUserPrefersMedical);
  const { id: dispensaryId, slug, tags } = useSelector(getCurrentDispensary);
  const isDualLicense = isDispensaryDualLicense(tags);

  const dispensaryLinks: { [key: string]: string } = links;

  const linkedDispensarySlug = dispensaryLinks[slug];
  const linkedDispensaryHref = route.replace("[slug]", linkedDispensarySlug);

  // Memoized values to determine which version to show
  const stringifiedTags = JSON.stringify(tags);
  // Show for dual licenses if Med is selected
  const showDualMenuMedical = useMemo(
    () => isDualLicense && userPrefersMedical,
    [userPrefersMedical, stringifiedTags],
  );
  // Show for medical dispensaries that are not recreational
  const showMedical = useMemo(
    () =>
      !isDualLicense &&
      tags.includes("medical") &&
      !tags.includes("recreational"),
    [stringifiedTags],
  );
  // Show for dispensaries that aren't dual license or medical if linked to another dispensary
  const showRecreational = useMemo(
    () => !isDualLicense && !tags.includes("medical") && !!linkedDispensarySlug,
    [stringifiedTags],
  );

  if (!(showDualMenuMedical || showMedical || showRecreational)) {
    return null;
  }

  const linkOnClick = () => {
    trackEvent(
      "Dispensary Menu",
      "Click",
      `Toggle to ${showMedical ? "Rec" : "Medical"} Menu`,
    );
    trackEvent("Med ID Required Banner", "click", `dispensary menu`, {
      dispensaryId,
    });
  };

  const isLinkedDispensary = linkedDispensarySlug && !isDualLicense;

  const linkedMenuLinkText = `Shop the ${
    showMedical ? "recreational" : "medical"
  } menu instead`;

  const linkedMenuMessage =
    linkedDispensarySlug && showMedical
      ? "You need a medical ID to order from this menu."
      : "This is the recreational menu.";

  return (
    <AlertBanner
      className="mb-xl"
      heading={
        showDualMenuMedical || showMedical
          ? "MEDICAL ID REQUIRED"
          : "LOOKING FOR THE MEDICAL MENU?"
      }
      icon={<MedicalCrossIcon height="24" width="24" className="text-yellow" />}
      linkOnClick={linkOnClick}
      linkText={
        isLinkedDispensary ? linkedMenuLinkText : "Search doctors near you"
      }
      linkHref={
        isLinkedDispensary ? linkedDispensaryHref : "/medical-marijuana-doctors"
      }
      message={
        isLinkedDispensary
          ? linkedMenuMessage
          : "Don't have one yet? Get your med card quickly."
      }
      showCaret={showDualMenuMedical}
      trackImpression={() =>
        trackEvent("Med ID Required Banner", "impression", `dispensary menu`, {
          dispensaryId,
        })
      }
    />
  );
};

export default MedicalInfo;
